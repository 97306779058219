import type { FetchError } from 'ofetch'

export default defineNuxtRouteMiddleware(async (to) => {
  // error handler
  const { doHandleError } = useErrorHandler()

  // nuxt app
  const { $actor } = useNuxtApp()

  // router
  const router = useRouter()

  // stores
  const tutorPackageMatchStore = useTutorPackageMatchStore()

  const lastStepCompleted = $actor.core.storage.getUniversal(
    'tutorMatchingLastStepCompleted'
  )

  try {
    const tutorPackageMatch = await tutorPackageMatchStore.show(
      Number(to.params.matchId),
      {
        params: {
          include: 'confirmed_by'
        }
      }
    )

    if (
      lastStepCompleted === 3 &&
      !tutorPackageMatch.value.possibleEvents.includes('confirm') &&
      tutorPackageMatch.value.confirmedBy?.type !== 'Employee'
    ) {
      await router.push(
        `/tutor-matching/${to.params.matchId}/complete?completed=true`
      )
    }
  } catch (error) {
    doHandleError(error as FetchError)
  }
})
